@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700');

// Font Family
$fontHeader: 'Quicksand', sans-serif;
$fontStack: 'Quicksand', sans-serif;

// Font Size
$size6px:0.375em;
$size7px:0.438em;
$size8px:0.500em;
$size9px:0.563em;
$size10px:0.625em;
$size11px:0.688em;
$size12px:0.750em;
$size13px:0.813em;
$size14px:0.875em;
$size15px:0.938em;
$size16px:1.000em;
$size17px:1.063em;
$size18px:1.125em;
$size19px:1.188em;
$size20px:1.250em;
$size21px:1.313em;
$size22px:1.375em;
$size23px:1.438em;
$size24px:1.500em;


$white: #ffffff;
$blue: #B7CEFC;
$darkblue: #08104D;
$lightblue: #E4ECF7;
$lightblue2: #F2F6FC;
$orange: #e84c1e;
$orange-light: rgba(246, 215, 212, 0.5);
$orange-hover: #e86651;
$orange-active: #c7461d;
$green: #14aea7;
$green-light: rgba(20, 174, 167, 0.3);
$yellow: #f9b233;
$grey: #565656;
$grey-hover: #9d9c9c;
$grey-active: #565656;
$lightgrey: #b2b2b1;
$lightbrown: #e9e4e1;
$darkbrown: #ccc6c6;

$defaultColor:$grey; //grey
$defaultColorHover:lighten($defaultColor,5%); //lighter grey
$primaryColor:$darkblue;//#1a212f; // dark blue (main app color)
$primaryColorHover:lighten($primaryColor,5%); //lighter dark blue (main app hover color)

$successColor:#14AEA7; //turquoise
$successColorHover:lighten($successColor,5%); //lighter turquoise
$successBackgroundColor: #D7F4F3;

$warningColor:#FF9200; //yellow
$warningColorHover:lighten($warningColor,5%); //lighter yellow
$warningBackgroundColor: #FFFBE5;

$infoColor:#1F7BB6; //blue
$infoColorHover:lighten($infoColor,5%); //lighter blue
$infoBackgroundColor: #E4ECF7;

$dangerColor:#D80303; //red
$dangerColorHover:lighten($dangerColor,5%); //lighter red
$dangerBackgroundColor: #F9E9E9;

//Main link color
$aLinkColor:#2E2E32;
$aLinkHover:#999;

