.full-width {
    width: 100%;
}

 // THIS SHOULD BE DONE ANOTHER WAY
//
//.mat-selected {
//        color: rgba(0,0,0,.42)!important;
//    }
//.mat-calendar-body-selected { // datepicker selected date
//    background-color: rgba(0,0,0,.38)!important;
//}
//.mat-select-arrow {
//    color: rgba(0,0,0,.42)!important;
//}
//.mat-select-underline {
//    color: rgba(0,0,0,.42)!important;
//    border-color: rgba(0,0,0,.42)!important;
//    background-color: rgba(0,0,0,.42)!important;
//}
//.mat-form-field {
//    &.mat-focused {
//        .mat-form-field-label {
//            color: rgba(0, 0, 0, 0.54);
//        }
//
//        .mat-form-field-ripple {
//            background-color: #1d212a;
//        }
//    }
//
//    .mat-form-field-ripple {
//        background-color: #1d212a;
//    }
//
//    .mat-form-field-suffix {
//        align-self: flex-end!important;
//    }
//
//    .mat-form-field-labeler
//    {
//        border-bottom-color: rgba(0, 0, 0, 0.54);
//    }
//}
//
//.mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-arrow, .mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-trigger {
//    color: initial!important;
//}
//
//.select-company {
//    padding: 0!important;
//    div {
//        height: 24px;
//        font-size: 14px;
//        font-weight: 500;
//        .mat-select-underline {
//            display: none;
//        }
//    }
//}
//
//.mat-dialog-container {
//    margin: auto;
//}
//
//.mat-card-header-text {
//    width: 100%;
//}