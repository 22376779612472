@media print {
  .doNotPrint {
    display: none !important;
  }
}
@page {
  size: A4;
  margin: 0;
}
.frame {
  position: absolute;
  top: -1000000px;
}

.align-right {
  float: right;
}

.icon-pointer {
  cursor: pointer;
}

button.close {
  background-color: #ffffff;
  border: none;
}

.close:focus {
  outline: none;
}

.viewBar {
  border-bottom: 1px solid grey;
}
.viewBar button.export-pdf {
  color: rgba(29, 33, 42, 0.9);
  box-shadow: none;
}
.viewBar button.export-pdf:hover {
  color: rgba(0, 0, 0, 0.12);
}

.pdpView {
  font-size: 11pt;
  padding: 10px 0;
}
.pdpView .page1, .pdpView .page2, .pdpView .page3, .pdpView .page4, .pdpView .page5, .pdpView .page6, .pdpView .page7, .pdpView .page8, .pdpView .page9, .pdpView .page10 {
  position: relative;
  background-color: #FFFFFF;
  margin: 10px 10px;
  padding: 10px 10px;
  max-width: 21cm;
  height: 29.7cm;
}
.pdpView .page1 .footer, .pdpView .page2 .footer, .pdpView .page3 .footer, .pdpView .page4 .footer, .pdpView .page5 .footer, .pdpView .page6 .footer, .pdpView .page7 .footer, .pdpView .page8 .footer, .pdpView .page9 .footer, .pdpView .page10 .footer {
  page-break-after: always;
  font-size: 0.8em;
  width: 98%;
  position: absolute;
  bottom: 5px;
}
.pdpView .page1 .footer tr, .pdpView .page2 .footer tr, .pdpView .page3 .footer tr, .pdpView .page4 .footer tr, .pdpView .page5 .footer tr, .pdpView .page6 .footer tr, .pdpView .page7 .footer tr, .pdpView .page8 .footer tr, .pdpView .page9 .footer tr, .pdpView .page10 .footer tr {
  width: 100%;
}
.pdpView .page1 .footer tr .pp-name, .pdpView .page2 .footer tr .pp-name, .pdpView .page3 .footer tr .pp-name, .pdpView .page4 .footer tr .pp-name, .pdpView .page5 .footer tr .pp-name, .pdpView .page6 .footer tr .pp-name, .pdpView .page7 .footer tr .pp-name, .pdpView .page8 .footer tr .pp-name, .pdpView .page9 .footer tr .pp-name, .pdpView .page10 .footer tr .pp-name {
  left: 0.5cm;
}
.pdpView .page1 .footer tr .page, .pdpView .page2 .footer tr .page, .pdpView .page3 .footer tr .page, .pdpView .page4 .footer tr .page, .pdpView .page5 .footer tr .page, .pdpView .page6 .footer tr .page, .pdpView .page7 .footer tr .page, .pdpView .page8 .footer tr .page, .pdpView .page9 .footer tr .page, .pdpView .page10 .footer tr .page {
  text-align: right;
  right: 0.5cm;
}
.pdpView .page1 .top {
  min-height: 25%;
  height: 25%;
}
.pdpView .page1 .title {
  text-align: center;
  font-size: 2.2em;
}
.pdpView .page1 .logo {
  text-align: center;
}
.pdpView .page1 .block-left {
  min-width: 35%;
  width: 35%;
}
.pdpView .page1 .title-line {
  text-align: left;
  font-size: 11pt;
  font-weight: bold;
}
.pdpView .page2 .title, .pdpView .page3 .title, .pdpView .page4 .title, .pdpView .page5 .title, .pdpView .page6 .title, .pdpView .page7 .title, .pdpView .page8 .title, .pdpView .page9 .title, .pdpView .page10 .title {
  text-align: left;
  font-size: 14pt;
  padding-left: 5px;
  font-weight: bold;
}
.pdpView .page2 .subtitle, .pdpView .page3 .subtitle, .pdpView .page4 .subtitle, .pdpView .page5 .subtitle, .pdpView .page6 .subtitle, .pdpView .page7 .subtitle, .pdpView .page8 .subtitle, .pdpView .page9 .subtitle, .pdpView .page10 .subtitle {
  background-color: #263144;
  border: 1px solid #000000;
  padding: 0cm 0.19cm;
}
.pdpView .page2 .subtitle p, .pdpView .page3 .subtitle p, .pdpView .page4 .subtitle p, .pdpView .page5 .subtitle p, .pdpView .page6 .subtitle p, .pdpView .page7 .subtitle p, .pdpView .page8 .subtitle p, .pdpView .page9 .subtitle p, .pdpView .page10 .subtitle p {
  color: #ffffff;
  font-weight: bold;
}
.pdpView .page2 .subtitle-grey, .pdpView .page3 .subtitle-grey, .pdpView .page4 .subtitle-grey, .pdpView .page5 .subtitle-grey, .pdpView .page6 .subtitle-grey, .pdpView .page7 .subtitle-grey, .pdpView .page8 .subtitle-grey, .pdpView .page9 .subtitle-grey, .pdpView .page10 .subtitle-grey {
  background-color: #c0c0c0;
}
.pdpView .page2 .title-line, .pdpView .page3 .title-line, .pdpView .page4 .title-line, .pdpView .page5 .title-line, .pdpView .page6 .title-line, .pdpView .page7 .title-line, .pdpView .page8 .title-line, .pdpView .page9 .title-line, .pdpView .page10 .title-line {
  text-align: left;
  font-size: 11pt;
  padding-left: 5px;
  font-weight: bold;
}
.pdpView .page2 .comment-header, .pdpView .page3 .comment-header, .pdpView .page4 .comment-header, .pdpView .page5 .comment-header, .pdpView .page6 .comment-header, .pdpView .page7 .comment-header, .pdpView .page8 .comment-header, .pdpView .page9 .comment-header, .pdpView .page10 .comment-header {
  padding-left: 25px;
  font-weight: bold;
  text-align: left;
}
.pdpView .page2 .comment, .pdpView .page3 .comment, .pdpView .page4 .comment, .pdpView .page5 .comment, .pdpView .page6 .comment, .pdpView .page7 .comment, .pdpView .page8 .comment, .pdpView .page9 .comment, .pdpView .page10 .comment {
  padding-left: 25px;
  text-align: left;
  font-weight: normal;
}
.pdpView .page2 .borders, .pdpView .page3 .borders, .pdpView .page4 .borders, .pdpView .page5 .borders, .pdpView .page6 .borders, .pdpView .page7 .borders, .pdpView .page8 .borders, .pdpView .page9 .borders, .pdpView .page10 .borders {
  border: 1px solid #000000;
}
.pdpView .page2 .borders-without-top, .pdpView .page3 .borders-without-top, .pdpView .page4 .borders-without-top, .pdpView .page5 .borders-without-top, .pdpView .page6 .borders-without-top, .pdpView .page7 .borders-without-top, .pdpView .page8 .borders-without-top, .pdpView .page9 .borders-without-top, .pdpView .page10 .borders-without-top {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.pdpView .page2 .padding, .pdpView .page3 .padding, .pdpView .page4 .padding, .pdpView .page5 .padding, .pdpView .page6 .padding, .pdpView .page7 .padding, .pdpView .page8 .padding, .pdpView .page9 .padding, .pdpView .page10 .padding {
  padding: 0cm 0.19cm;
}

.pdpView {
  padding: 10px 0;
  background-color: #ffffff;
}
/* global */
table, th, td, tbody, tr {
  border-collapse: collapse;
}
td {
  vertical-align: middle;
}
.text-center{
  text-align: center;
}

.line-table th {
  text-align: left;
  vertical-align: top;
}
.padding-left-table tr th {
  padding-left: 30px;
}


.cellFirstLineLeft {
  border-top: 1.00pt solid #000000;
  border-bottom: 1.00pt solid #000000;
  border-left: 1.00pt solid #000000;
  border-right: none;
}
.cellFirstLine {
  border: 1.00pt solid #000000;
}
.cellFirstLineRight {
  border-top: 1.00pt solid #000000;
  border-bottom: 1.00pt solid #000000;
  border-left: none;
  border-right: 1.00pt solid #000000;
}

.cellLineLeft {
  border-top: 1.00pt solid #000000;
  border-bottom: 1.00pt solid #000000;
  border-left: 1.00pt solid #000000;
  border-right: none;
}
.cellLine {
  border-top: 1.00pt solid #000000;
  border-bottom: 1.00pt solid #000000;
  border-left: 1.00pt solid #000000;
  border-right: none;
}
.cellLineRight {
  border-top: 1.00pt solid #000000;
  border-bottom: 1.00pt solid #000000;
  border-left: 1.00pt solid #000000;
  border-right: 1.00pt solid #000000;
}
.cellLastLine {
  border-top: 1.00pt solid #000000;
  border-bottom: 1.00pt solid #000000;
  border-left: 1.00pt solid #000000;
  border-right: none;
}


.page1   .top{
  min-height: 25%;
  height: 25%;
}
.page1  .title {
  text-align: center;
  font-size: 2.2em;
}
.page1  .logo {
  text-align: center;
  position:relative;
  margin:50px auto;
  text-align: center;
  width:350px;
  height:200px;
}
.page1  .logo img {
  max-width: 100%;
  max-height: 100%;
}
.page1  .wrap {
  display: flex;
}
.page1  .title-line {
  text-align: left;
  font-size: 11pt;
  font-weight: bold;
}
.page1  .line {
  text-align: left;
  font-size: 11pt;
}




.pages .title {
  text-align: left;
  font-size: 14pt;
  padding-left: 5px;
  font-weight: bold;
  page-break-before: always;
}
.pages .subtitle {
  background-color: #263144;
  border: 1px solid #000000;
  padding: 0 0.19cm;
}
.pages .subtitle p {
  color: #FFFFFF;
  font-weight: bold;
}

.pages   .title-line {
  text-align: left;
  font-size: 11pt;
  padding-left: 5px;
  font-weight: bold;
}
.pages   .line {
  text-align: left;
  font-size: 11pt;
  padding-left: 5px;
}

.pages  .subtitle-grey {
  background-color: #c0c0c0;
}
.pages  .subtitle-lightgrey {
  background-color: #e6e6e6;
}
.pages  .subtitle-grey   td {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  padding-top: 0cm;
  padding-bottom: 0cm;
  padding-left: 0.19cm;
  padding-right: 0cm;
}
.pages  .subtitle-lightgrey td {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  padding-top: 0cm;
  padding-bottom: 0cm;
  padding-left: 0.19cm;
  padding-right: 0cm;
}

.pages  .borders {
  border: 1px solid #000000;
}
.pages   .border-tr{
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
  padding-top: 0cm;
  padding-bottom: 0;
  padding-left: 0.19cm;
  padding-right: 0;
}
.pages   .borders-trb{
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.19cm;
  padding-right: 0;
}
.pages   .borders-tbl {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.19cm;
  padding-right: 0;
}
.pages   .borders-trl{
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.19cm;
  padding-right: 0;
}
.pages   .borders-tr{
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.19cm;
  padding-right: 0;
}
.pages   .borders-r{
  border-right: 1px solid #000000;
  text-align: center;
}
.pages   .borders-rb{
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
}
.pages   .borders-rbl {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.pages   .borders-rl {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
.pages  .borders-b{
  border-bottom: 1px solid #000000;
}
.pages  .borders-bl{
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.19cm;
  padding-right: 0;
}
.pages   .avoid {
  page-break-inside: avoid;
}
.pages    .margin-top-bottom-table {
  margin: 5px 0 5px 0;
}
.pages  .margin-top-table {
  margin-top: 15px;
}
.pages  .margin-top-subtable {
  margin-top: 5px;
}
.pages   .margin-top-tbody tbody tr {
  margin-top: 10px;
}
.pages   .margin-tbody tbody tr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.pages   .padding-left-table {
  padding-left: 30px;
}
.pages  .eESignatories     td {
  height: 146px;
}
.pages  .eESignatories     td    .signatory {
  height: 100px;
  width: 200px;
}
.pages      .comment-header {
  font-weight: bold;
}