/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800");

@import "app/styles/_variables.scss";
@import "app/styles/_base.scss";
@import "app/styles/_global.scss";
// TODO : insert custom theming for material component
//@import "app/styles/_material.scss";
.ldcmDialog {
  .mat-dialog-container {
    overflow: hidden !important;
    .mat-dialog-content {
      max-height: 60vh;
    }
  }
}
//fix bug [mat Tabs] Scrollbar shows on tab changes
.mat-tab-body-content {
  &.ng-animating {
    overflow: hidden !important;
  }
}